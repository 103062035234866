<template>
    <div>
        <v-snackbar v-model="snackbar" :top="true" :color="color" :timeout="6000">
            <span v-if="snackbar_msg != null">{{ snackbar_msg }}</span>
            <v-btn dark text @click="snackbar = false">Close</v-btn>
        </v-snackbar>
        <v-row>
            <div style="position: fixed; z-index: 1;  background-color: white; width: 100%; top:55px;">
                <v-breadcrumbs :items="items" large style=" font-weight: 600; letter-spacing: 1px; ">

                </v-breadcrumbs>
            </div>
        </v-row><br><br><br />
        <div v-if="init_loading">
            <v-skeleton-loader v-bind="attrs" type="three-line,  article"></v-skeleton-loader>
        </div>
        <div v-else>
            <v-card class="card">

                <v-card-title class="heading justify-center"> Reserve Book</v-card-title>


                <v-row class="px-5 pt-4">
                    <v-col cols="12" md="3" sm='3'>
                        <v-autocomplete v-model="selectedisbn" outlined label="ISBN" :items="isbnlist" item-text="name"
                            item-value="id"></v-autocomplete>
                    </v-col>
                    <v-col cols="12" md="3" sm='3'>
                        <v-autocomplete v-model="selectedtitle" outlined label="Book Title" :items="titlelist"
                            item-text="name" item-value="id"></v-autocomplete>
                    </v-col>

                    <v-col cols="12" md="3" sm='3'>
                        <v-autocomplete v-model="selecteddepartment" outlined label="Department" :items="departmentlist"
                            item-text="name" item-value="id"></v-autocomplete>
                    </v-col>
                    <v-col cols="12" md="3" sm='3'>
                        <v-autocomplete v-model="selectedpublisher" outlined label="Publisher" :items="publisherlist"
                            item-text="name" item-value="id"></v-autocomplete>
                    </v-col>
                </v-row>

                <v-row class="px-6" justify="center" align="center">
                    <v-divider></v-divider>
                    <span class="">
                        <h4>OR</h4>
                    </span>
                    <v-divider></v-divider>
                </v-row>
                <v-row class="" justify="center" align="center">
                    <v-col cols="12" md="4" sm='4'>
                    </v-col>

                    <v-text-field class="" @input="reloadAllBook" placeholder="Search by Accession No" v-model="a_no"
                        outlined dense></v-text-field>
                    <v-col cols="12" md="4" sm='4'>
                    </v-col>

                </v-row>
                <v-row justify="center" align="center">
                    <v-btn class="m-5" color="info" @click="searchbtn">Search</v-btn>
                </v-row>



                <center v-if="limit">
                    <p class="blink_me">Reservation Book limit Exeeds!!!</p>
                </center>

                <div v-if="userInfo" class="cus-card">
                    <v-row>
                        <v-col lg="2"> <label class="c-label">Name</label></v-col>
                        <v-col><span>: {{ empmemberinfo.user }}</span></v-col>
                        <v-col lg="3"> <label class="c-label">Total Checked-Out Book</label></v-col>
                        <v-col><span>: {{ empmemberinfo.total_checked_out_book }}</span></v-col>
                    </v-row>
                    <v-row>
                        <v-col lg="2"> <label class="c-label">User Type</label></v-col>
                        <v-col><span>: {{ empmemberinfo.usertype }}</span></v-col>
                        <v-col lg="3"> <label class="c-label">Date of Membership</label></v-col>
                        <v-col><span>: {{ empmemberinfo.date_of_membership }}</span></v-col>
                    </v-row>
                    <v-row>
                        <v-col lg="2"> <label class="c-label">Contact</label></v-col>
                        <v-col><span>: {{ empmemberinfo.mobile_number }}</span></v-col>
                        <v-col lg="3"> <label class="c-label">Email</label></v-col>
                        <v-col><span>: {{ empmemberinfo.email }}</span></v-col>
                    </v-row>
                    <v-row>
                        <v-col lg="2"> <label class="c-label">Address</label></v-col>
                        <v-col><span>: {{ empmemberinfo.address }}</span></v-col>
                        <v-col lg="3"> <label class="c-label">City</label></v-col>
                        <v-col><span>: {{ empmemberinfo.city }}</span></v-col>
                    </v-row>
                    <v-row>
                        <v-col lg="2"> <label class="c-label">State</label></v-col>
                        <v-col><span>: {{ empmemberinfo.state }}</span></v-col>
                        <v-col lg="3"> <label class="c-label">Country</label></v-col>
                        <v-col><span>: {{ empmemberinfo.country }}</span></v-col>
                    </v-row>
                    <v-row>
                        <v-col lg="2"> <label class="c-label">Pin</label></v-col>
                        <v-col><span>: {{ empmemberinfo.pin }}</span></v-col>

                    </v-row>
                </div>
                <v-card-text class="pt-0">

                    <div v-if="loader">
                        <v-skeleton-loader v-bind="attrs" type="three-line,  article"></v-skeleton-loader>
                    </div>
                    <div v-else>
                        <v-data-table v-model="selected" :item-class="row_classes" :headers="headers" :items="bookList1"
                            item-key="name" class="elevation-1" :search="search" @current-items="getFiltered">
                            <v-spacer></v-spacer>
                            <template v-slot:top>
                                <v-toolbar flat color="white">
                                    <v-spacer></v-spacer>
                                    <v-text-field v-model="search" label="Search" append-icon="mdi-magnify" single-line
                                        hide-details> </v-text-field>
                                    <download-excel class="ml-5" :data="filterArr" worksheet="bookItemlist"
                                        name="BookItem.xls">
                                        <v-btn color="success" dark class="mb-2"> Export<v-icon dark>mdi-file-excel</v-icon>
                                        </v-btn>
                                    </download-excel>
                                </v-toolbar>
                                <tr>
                                    <div>
                                        <th class="d-flex">
                                            <span v-for="header in headers" :key="header.text">
                                                <span v-if="filters.hasOwnProperty(header.value)">
                                                    <v-autocomplete class="m-2" flat hide-details small dense outlined
                                                        :label="header.text" multiple clearable
                                                        :items="columnValueList(header.value)"
                                                        v-model="filters[header.value]"></v-autocomplete>
                                                </span>
                                            </span>
                                            <!-- <v-autocomplete class="m-2" @change="searchByAuthor" flat hide-details small dense outlined label="Authors" multiple clearable :items="authorsList" v-model="selected_author" item-text="name" item-value="id"></v-autocomplete> -->
                                        </th>
                                    </div>
                                </tr>
                            </template>
                            <template v-slot:item.no_copies="{ item }">
                                <!-- <router-link class="bookItem" :to="{ name: 'book-item', params: {bookId: item.id} }" style="text-decoration: none;">
                           {{item.copies}}
                        </router-link>-->
                                {{ item.copies }}
                            </template>
                            <!-- <template v-slot:item.reserve_book="{ item }"> -->
                            <template v-slot:item.reserve_book="{ item }">
                                <v-checkbox v-model="reserve_book" :disabled="item.isReserve || limit" @click="checkLimit"
                                    :value="item.id"></v-checkbox>
                            </template>
                            <template v-slot:item.no_available_copies="{ item }">
                                <!-- <router-link  class="bookItem"  :to="{ name: 'book-item', params: {bookId: item.id} }" style="text-decoration: none;">
                           {{item.available}}
                        </router-link>-->
                                {{ item.available }}
                            </template>
                            <template v-slot:item.authors="{ item }">
                                <span v-for="author in item.authors" :key="author">{{ author }}<br /></span>
                            </template>
                        </v-data-table>
                    </div>
                    <center>
                        <v-btn v-if="reserve_book.length !== 0" class="mt-4" color="primary" @click="reserveBook">
                            Reserve Book
                        </v-btn>
                    </center>
                </v-card-text>

            </v-card>
            <v-card>
                <v-card-title>Reserve Book List</v-card-title>
                <div v-if="loaderss">
                    <v-skeleton-loader v-bind="attrs" type="three-line,  article"></v-skeleton-loader>
                </div>
                <div v-else>
                    <v-card-text>
                        <v-data-table :item-class="row_classes" :headers="headers" :items="reserv_book_list" item-key="name"
                            class="elevation-1" :search="search" @current-items="getFiltered">
                            <v-spacer></v-spacer>
                            <template v-slot:top>
                                <v-toolbar flat color="white">
                                    <v-spacer></v-spacer>
                                    <v-text-field v-model="search" label="Search" append-icon="mdi-magnify" single-line
                                        hide-details> </v-text-field>
                                    <download-excel class="ml-5" :data="filterArr" worksheet="bookItemlist"
                                        name="BookItem.xls">
                                        <v-btn color="success" dark class="mb-2"> Export<v-icon dark>mdi-file-excel</v-icon>
                                        </v-btn>
                                    </download-excel>
                                </v-toolbar>

                            </template>
                            <template v-slot:item.no_copies="{ item }">

                                {{ item.copies }}
                            </template>


                            <template v-slot:item.no_available_copies="{ item }">

                                {{ item.available }}
                            </template>
                            <template v-slot:item.authors="{ item }">
                                <span v-for="author in item.authors" :key="author">{{ author }}<br /></span>
                            </template>
                        </v-data-table>

                    </v-card-text>
                </div>
            </v-card>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import "@/assets/css/color.css"; // css needs to be imported for each dashboard
export default {
    data: () => ({

        publisherlist: [],
        departmentlist: [],
        autherlist: [],
        titlelist: [],
        isbnlist: [],
        reserv_book_list: [],
        selectedpublisher: "ALL",
        selecteddepartment: "ALL",
        selectedauthor: "ALL",
        selectedtitle: "ALL",
        selectedisbn: "ALL",
        a_no: null,
        valid: false,
        init_loading: false,
        loader: false,
        loaderss: false,
        userid: "",
        bookitemid: "",
        errorMsg: {
            roleLinkId: false,
        },
        items: [
            {
                text: 'Dashboard',
                disabled: false,
                href: '/home',
            },
            {
                text: localStorage.getItem("jhhjbyj"),
                disabled: false,
                href: '/sublink',
            },
            {
                text: 'Reserve Book',
                disabled: true,
                href: 'breadcrumbs_link_2',
            },
        ],
        rules: {
            required: (value) => !!value || "Required.",
        },
        snackbar_msg: "",
        reserve_book: [],
        bookLimit: false,
        limit: false,
        color: "",
        snackbar: false,
        empmemberlist: [],
        empmemberinfo: [],
        bookinformation: [],
        empmemberbooklendinginfo: [],
        allBookInfo: [],
        selecteduser: "",
        selectedbookitem: "",
        org: "",
        inSave: true,
        dialog: false,
        headers: [
            {
                text: "Sr No",
                value: "srno",
            },
            { text: "ISBN", value: "isbn" },
            { text: "Title", value: "title" },
            { text: "Reservation Status", value: "reservation_status" },
            { text: "Reserve Book", value: "reserve_book" },
            { text: "Edition", value: "edition" },
            //   { text: 'Book Format', value: 'bookFormat' },
            { text: "Book Type", value: "bookType" },
            { text: "Authors", value: "authors" },
            { text: "Category", value: "category" },
            { text: "Publisher", value: "publisher" },
            { text: "No of Copies", value: "no_copies" },
            { text: "Available Copies", value: "no_available_copies" },
        ],
        search: "",
        filters: {
            isbn: [],
            title: [],
            category: [],
            publisher: [],
            authors: [],
        },
        bookList: [],
        filterArr: [],
        bookCategoryList: [],
        roleLink_list: [],
        role_list: [],
        userInfo: false,
        bookInfo: false,
        alreadyReserved: null,
        reservationLimit: null,
        total_reserveChecked: null,
    }),

    computed: {
        bookList1() {
            return this.bookList.filter((d) => {
                return Object.keys(this.filters).every((f) => {
                    return this.filters[f].length < 1 || this.filters[f].includes(d[f]);
                });
            });
        },
    },
    mounted() {

        this.init_loading = true;
        axios
            .post("/SearchCatalog/getBookFilter")
            .then((res) => {

                if (res.data.msg == "200") {
                    this.init_loading = false;
                    this.isbnlist = res.data.isbnlist;
                    this.publisherlist = res.data.publisherlist;
                    this.departmentlist = res.data.departmentlist;
                    //    this.autherlist = res.data.autherlist;
                    this.titlelist = res.data.titlelist;

                }
            });
        this.viewreservebooklist();
    },
    methods: {
        row_classes(item) {
            if (item.isReserve) {
                return "yellow"; //can also return multiple classes e.g ["orange","disabled"]
            }
        },


        viewreservebooklist() {
            this.loaderss = true;


            axios
                .post("/ReserveBookMember/viewreservebooklist")
                .then((res) => {
                    console.log('res--', res);
                    if (res.data.msg == "200") {
                        this.loaderss = false;
                        this.reserv_book_list = res.data.reserv_book_list;



                    }
                })
                .catch((error) => {
                    this.showSnackbar("#b71c1c", "Something went wrong..."); // show snackbar
                    window.console.log(" ajdfk js dkfj skjd fkj");
                    window.console.log(error);
                })
                .finally(() => {
                    this.loaderss = false;
                });
        },

        reserveBook() {
            this.loaderss = true;

            const data = {
                bookIds: this.reserve_book,
            }
            axios
                .post("/ReserveBookMember/reserveBook", data)
                .then((res) => {
                    console.log('res--', res);
                    if (res.data.msg == "200") {
                        this.loaderss = false;
                        this.showSnackbar("#4caf50", "Book reserved Successfully!!");
                        this.searchbtn();
                        this.viewreservebooklist();


                    }
                })
                .catch((error) => {
                    this.showSnackbar("#b71c1c", "Something went wrong..."); // show snackbar
                    window.console.log(error);
                })
                .finally(() => {
                    this.loaderss = false;
                });
        },

        onlyUnique(value, index, self) {
            if (value != null && value != "" && value)
                return self.indexOf(value) === index;
        },

        columnValueList(val) {
            var data = this.bookList.map((d) => d[val]);
            data = data.filter(this.onlyUnique);
            return data
        },
        getFiltered(e) {
            console.log(e);
            this.filterArr = e;
        },
        findUserInfo(seluser) {

            this.loaderss = true;
            axios
                .post("/ReserveBookMember/getEmpMemInformation", seluser)
                .then((res) => {
                    if (res.data.msg == "200") {
                        this.loaderss = false;
                        this.userInfo = true;
                        this.empmemberinfo = res.data.memberinfo;
                        this.empmemberbooklendinginfo = res.data.booklendinginformation;

                        if (this.empmemberinfo.max_number_book_allowed <= this.empmemberbooklendinginfo.length) {
                            this.bookLimit = true
                        }
                    }
                })
                .catch((error) => {
                    this.showSnackbar("#b71c1c", "Something went wrong..."); // show snackbar
                    window.console.log(error);
                })
                .finally(() => {
                    // var loaderss = false;
                });
        },
        checkLimit() {
            this.total_reserveChecked = this.alreadyReserved
            this.total_reserveChecked = this.total_reserveChecked + this.reserve_book.length

            if (this.total_reserveChecked > this.reservationLimit) {
                this.reserve_book.pop();
                this.showSnackbar("#b71c1c", "Book Reservation limit exeeds!!");
            }
        },
        searchbtn() {
            var params = { "selectedisbn": this.selectedisbn, "selectedtitle": this.selectedtitle, "selectedauther": this.selectedauther, "selecteddepartment": this.selecteddepartment, "selectedpublisher": this.selectedpublisher, "selectedauthor": this.selectedauthor };

            this.loaderss = true;
            axios
                .post("/reserveBookMember/reserveBookInit", params)
                .then((res) => {
                    if (res.data.msg == "200") {
                        this.loaderss = false;
                        this.empmemberlist = res.data.userlist;
                        this.bookList = res.data.bookList;
                        this.allBookInfo = res.data.allBookInfo;
                        this.alreadyReserved = res.data.reserved_count
                        this.reservationLimit = res.data.Reservation_limit
                        this.reserve_book = []
                        this.limit = res.data.isReservation_limit_exeed
                    }
                })
                .catch((error) => {
                    this.showSnackbar("#b71c1c", "Something went wrong..."); // show snackbar
                    window.console.log(error);
                })
                .finally(() => {
                    // var loaderss = false;
                });
        },
        showSnackbar(clr, msg) {
            this.snackbar = true;
            this.color = clr;
            this.snackbar_msg = msg;
        },
        close() {
            this.dialog = false;
            // this.$refs.form.reset()
            setTimeout(() => {
                this.editedItem = Object.assign({}, this.defaultItem);
                this.editedIndex = -1;
            }, 300);
        },

        isValidated() {
            if (this.editedItem.roleLinkId) {
                return true;
            } else {
                if (!this.editedItem.roleLinkId) {
                    this.errorMsg.roleLinkId = true;
                }

                return false;
            }
        }, // .....end of isValidated()
    },
};
</script>
<style scoped>
.add-section {
    display: block;
    margin: 1rem;
}

.blink_me {
    white-space: nowrap;
    color: red;
    animation: blinker 1s linear infinite;
    margin: 1rem;
    font-weight: bold;
    font-size: 20px;
}

@keyframes blinker {
    50% {
        opacity: 0;
    }
}

.bookItem {
    text-decoration: underline !important;
}

.text>>>.v-input__slot {
    background: white !important;
    border-color: hsla(190, 99%, 30%, 1) !important;
}

.elevation-1 /deep/ tr {
    white-space: nowrap !important;
}

.v-text-field {
    padding-top: 0px;
    margin-top: 0px;
}

.v-icon-size {
    font-size: 20px;
}

.edit-avatar {
    transition: 0.3s;
    border: 1px solid #b0bec5;
}

.edit-avatar:hover {
    background-color: #b0bec5;
}

.edit-v-icon {
    padding-left: 6px;
    font-size: 15px;
}

.edit-v-icon:hover {
    color: white;
}

.fields {
    padding: 0px !important;
}

.cus-card {
    background: #d3d3d33d;
    margin: 1rem;
    padding: 1rem;
    border: 2px solid gray;
}

.c-label {
    font-weight: bold;
}</style>
